<!--
 * @Description: AKJERRT
 * @Date: 2022-07-08 22:12:44
 * @LastEditTime: 2022-07-26 10:50:06
 * @FilePath: \linkgap-front\src\views\account_statement\components\table\overview.vue
-->
<template>
  <a-modal title="账户总览" width="50%" v-model="visible" :footer="null" :maskClosable="false">
    <a-row type="flex" justify="center">
      <a-col class="title"><span class="title-company">{{ rowData.dealerName }}</span> 对账单 </a-col>
    </a-row>
    <br />
    <a-row type="flex" justify="space-between">
      <a-col :span="16">客户名称： {{ rowData.dealerName }} </a-col>
      <a-col :span="6">账单周期: {{ rowData.startTime }}~{{ rowData.endTime }}</a-col>
    </a-row>
    <br />
    <a-row type="flex" justify="space-between">
      <a-col class="annotation" type="flex" justify="left">注：上期余额+本期增加-本期发货=本期结余</a-col>
    </a-row>
    <br />
    <!-- 数据表格 -->
    <a-table
      :loading="tableLoading"
      :rowKey="(record) => record.id"
      :columns="columns"
      :data-source="tableData"
      bordered
      :pagination="false"
    >
      <span slot="QK" slot-scope="text">
            <span class="TextRed">{{ text | formatMoney }}</span>
          </span>
      <span slot="amount" slot-scope="text"> {{ text | formatMoney }} </span>
      <span slot="statementsType" slot-scope="text">
            <a-tag v-if="text == 1">资金</a-tag>
            <a-tag v-if="text == 2">信用额度</a-tag>
            <a-tag v-if="text == 3">合计</a-tag>
          </span>
    </a-table>
    <br />
    <!-- <a-form :form="res_data" @submit="handleSubmit">
      <a-form-item label="账单核对" :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }">
        <a-radio-group v-model="res_data.value" @change="onChange">
          <a-radio :value="1"> 相符 </a-radio>
          <a-radio :value="2"> 不相符 </a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="对账说明" :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }">
        <a-textarea :rows="6" placeholder="对账说明" v-model="res_data.DZSM"></a-textarea>
      </a-form-item>
      <a-form-item label="上传附件" :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }">
        <QiniuUpload type="image" :disabled="handle == 'check'" :value.sync="res_data.fileUrl"></QiniuUpload>
      </a-form-item>
    </a-form> -->
  </a-modal>
</template>

<script>
const qs = require('qs')
export default {
  data() {
    return {
      res_data: {
        DZSM: '',
        fileUrl: '',
        value: 1,
      },
      tableLoading: false,
      visible: false,
      handle: 'add',
      rowData: {},
      dealerId: 0,
      statementsId: 0,
      tableData:[],
      value: '',
      columns: [
        {
          title: '项目',
          dataIndex: 'statementsType',
          key: 'statementsType',
          align: 'center',
          scopedSlots: { customRender: 'statementsType' },
        },
        {
          title: '欠款余额',
          align: 'center',
          className: 'headerYellow',
          children: [
            {
              title: '欠款',
              dataIndex: 'oweAmount',
              key: 'oweAmount',
              align: 'center',
              className: 'headerTextRed',
              scopedSlots: { customRender: 'QK' },
            },
          ],
        },
        {
          title: '可使用额度',
          align: 'center',
          className: 'headerBlue',
          children: [
            {
              title: '上期余额',
              dataIndex: 'lastMonthAmount',
              key: 'lastMonthAmount',
              align: 'center',
              scopedSlots: { customRender: 'amount' },
            },
            {
              title: '本期增加',
              dataIndex: 'addAmount',
              key: 'addAmount',
              align: 'center',
              scopedSlots: { customRender: 'amount' },
            },
            {
              title: '本期失效额度',
              dataIndex: 'efficacyAmount',
              key: 'efficacyAmount',
              align: 'center',
              scopedSlots: { customRender: 'amount' },
            },
            {
              title: '本期发货',
              dataIndex: 'shipAmount',
              key: 'shipAmount',
              align: 'center',
              scopedSlots: { customRender: 'amount' },
            },
            {
              title: '可使用结余',
              dataIndex: 'canUseAmount',
              key: 'canUseAmount',
              align: 'center',
              scopedSlots: { customRender: 'amount' },
            },
          ],
        }
      ],
    }
  },
  mounted() {},
  methods: {
    handleSubmit(e) {},
    setRowData(row) {
      this.visible = true
      this.rowData = row
      this.dealerId = row.dealerId
      this.statementsId = row.id
      this.getData()
    },
    getData() {
      let params = {
        statementsId: this.statementsId,
      }
      const postData = Object.assign(params)
      this.axios
        .get(`/api/order/statements/dealerStatementsDetail/list?${qs.stringify(postData, {arrayFormat: 'repeat',})}`)
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            // this.page.total = total
            this.tableData = records
            const table = {};
            table.statementsType = 3;
            this.tableData.forEach((item) => {
              //遍历并累加
              table.oweAmount = table.oweAmount || 0 + item.oweAmount;
              table.addAmount = table.addAmount || 0 + item.addAmount;
              table.lastMonthAmount = table.lastMonthAmount || 0 + item.lastMonthAmount;
              table.shipAmount = table.shipAmount || 0 + item.shipAmount;
              table.efficacyAmount = table.efficacyAmount || 0 + item.efficacyAmount;
              table.canUseAmount = table.canUseAmount || 0 + item.canUseAmount;
            });
            this.tableData.push(table)
          }
        })
    },
    // 账单核对
    // onChange(data) {
    //   const ID = data.target.value
    //   if (ID == 1) {
    //   } else {
    //   }
    //   console.log(data.target.value)
    // },
    toSubmit() {
      this.visible = false
    },
    cancel() {
      this.visible = false
    },
  },
  created() {},
}
</script>

<style lang="less" scoped>
.footer-btns {
  text-align: right;
}
.title {
  font-size: 1.5rem;
  font-weight: 900;
  .title-company {
    border-bottom: 1px double black;
    cursor: pointer;
  }
}
::v-deep .ant-table-thead > tr > th.headerYellow {
  background-color: #fff2cc;
}
::v-deep .ant-table-thead > tr > th.headerBlue {
  background-color: #ddebf7;
}
::v-deep .ant-table-thead > tr > th.headerTextRed {
  color: red;
  font-weight: 600;
}
.TextRed {
  color: red;
}
.annotation {
  color: orange;
  font-size: 12px;
}
</style>
